import React from 'react'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import useFetchOrder from '../../CustomHooks/FetchOrders'
function OrderDetail () {
  const [orderData] = useFetchOrder(1)
  console.log('order details', orderData)
  return (
    <>
      <div className='container'>
        <div className='table-responsive manage-pools'>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>User Id</th>
                <th>Buy Amount</th>
                <th>Sell Amount</th>
                <th>Actions</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orderData.length > 0 ? (
                orderData.map((item, index) => {
                  return (
                    <tr key={index}> 
                      <td>{item.poolId}</td>
                      <td>{item.userId}</td>
                      <td>{item.buyAmount}</td>
                      <td>{item.sellAmount}</td>
                      <td><FontAwesomeIcon className='del-icon' icon={faTrash} /></td>
                    </tr>
                  )
                })
              ) : (
               null
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {/* <Table striped bordered hover variant='dark'>
        <thead>
          <tr>
            <th>Order No</th>
            <th>Order id</th>
            <th>Pool Id</th>
            <th>Sell Amount</th>

            <th>Buy Amount</th>
          </tr>
        </thead>
        <tbody>
          {orderData.length > 0 ? (
            orderData.map((item,index)=>{



        return(
            <tr>
              <td>{item.poolId}</td>
              <td colSpan={2}>{item.userId}</td>
              <td>{item.buyAmount}</td>
              <td>{item.sellAmount}</td>
            </tr>
        )
            })
          ) : (
            <>Unable to Fetch Data</>
          )}
        </tbody>
      </Table> */}
    </>
  )
}

export default OrderDetail
