import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { Row, Col, Form } from 'react-bootstrap'
import axios from 'axios'
function Setting () {
  const [settingDetail, setSettingDetails] = useState({
    site_title: '',
    site_description: '',
    meta_title: '',
    meta_keywords: '',
    meta_description: '',
    telegram_link: '',
    medium_link: '',
    twitter_link: '',
    website_link: ''
  })
  // const handleChange = e => {
  //   const { name, value } = e.target

  //   setSettingDetails({ ...settingDetail, [name]: value })
  // }
  useEffect(() => {
    axios
      .get(
        'https://holdex-ido-server.server18.arhamsoft.info/api/site_settings'
      )
      .then(res => {
        console.log('setting responce', res.data.data)
        setSettingDetails(res.data.data)
      })
      .catch(err => {
        console.log('Error in  fetching settings Component', err)
      })
  }, [])

  return (
    <div>
      <div className='content'>
        <div className='cms-pg'>
          <Card>
            <Card.Header className='titles-sett text-center'>
              Site Settings
              {/* <button className='d-inline light-blue-btn text-capitalize'>
                Add
              </button> */}
            </Card.Header>
            <Card.Body>
              <div className='cms-pages-style'>
                <div className='container'>
                  <Row>
                    <Col sm={12}>
                      <div className='cms-pages-style'>
                        <Form>
                          <Form.Group className='mb-2'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type='text'
                              name='site_title'
                              placeholder='Enter Name'
                              value={settingDetail.site_title}
                            />
                          </Form.Group>
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Meta Title</Form.Label>
                            <Form.Control
                              type='text'
                              name='meta_title'
                              placeholder='Enter meta_title'
                              value={settingDetail.meta_title}
                              readOnly
                              disabled={true}
                            />
                          </Form.Group>
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                                 <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>meta_keywords</Form.Label>
                            <Form.Control
                              type='text'
                              name='site_meta_keywords'
                              placeholder='Enter meta_keywords'
                              value={settingDetail.meta_keywords}
                            />
                          </Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as='textarea'
                              rows={2}
                              name='site_description'
                              placeholder='Enter Description'
                              value={settingDetail.site_description}
                            />
                          </Form.Group>
                       
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Twitter Link</Form.Label>
                            <Form.Control
                              type='link'
                              name='site_'
                              placeholder='Enter Twitter Link'
                              value={settingDetail.twitter_link}
                            />
                          </Form.Group>
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Telegram Link</Form.Label>
                            <Form.Control
                              type='link'
                              name='site_'
                              placeholder='Enter Twitter Link'
                              value={settingDetail.telegram_link}
                            />
                          </Form.Group>{' '}
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Medium Link</Form.Label>
                            <Form.Control
                              type='link'
                              name='site_'
                              placeholder='Enter Twitter Link'
                              value={settingDetail.medium_link}
                            />
                          </Form.Group>{' '}
                       
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Setting
