import { useEffect, useState, useCallback } from 'react'
import { createClient } from 'urql'
function useFetchAllOrder () {
  // import data from '../utils/api'
  // import { ethers } from 'ethers'
  const [AllOrders, setAllOrders] = useState([])

  const API_URL = process.env.REACT_APP_SUBGRAPH_API
  const fetchGraphData = useCallback(async () => {
    const tokensQuery = `
          query{
             
  pools(orderBy:poolId, orderDirection:asc)
  {
    poolId
       poolingTokenAddress
    biddingTokenAddress
    poolEndDate
    poolStartDate
    initialsupply
  } 
          }
                 `
    const client2 = createClient({
      url: API_URL
    })

    const data2 = await client2.query(tokensQuery).toPromise()

    // data2.then(res=>{
    //   console.log("responce form graph",res);
    // }).catch(err=>{
    //   console.log("Problem is Subgraph Check")
    // })
    console.log(' All graph data  ---', data2)

    if (data2.data.pools !== null || data2.data.pools !== undefined) {
      setAllOrders(data2.data.pools)
    }
    // setDataG()
  }, [API_URL])

  useEffect(() => {
    fetchGraphData()
  }, [fetchGraphData])
  return [AllOrders]
}

export default useFetchAllOrder
