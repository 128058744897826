import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from './components/header'
// import Footer from './components/footer'
import Landing from './components/landingPage/landingPage'
import Product from './components/ProductPage/ProductPage'
import Createpool from './components/CreatePool/createpool'
import Terms from './components/Terms/Terms'
import Privacy from './components/Privacy/Privacy'
import Admin from './components/AdminPanel/admin'
// import useGetOwner from './CustomHooks/GetOwner'
import { useCookies } from 'react-cookie'
// import ManagePools from './components/AdminPanel/ManagePools/ManagePools'
// import CmsPage from './components/AdminPanel/CMSPAGES/cmspage'
// import Setting from './components/AdminPanel/Settings/setting'
import { ethers } from 'ethers'

function App () {
  const [cookies, setCookies] = useCookies(['address'])

  // const [ownerFlag] = useGetOwner(cookies.address)
  useEffect(() => {
    async function fetch () {
      const { ethereum } = window

      if (ethereum) {
        var provider = new ethers.providers.Web3Provider(ethereum)
      }

      const isMetaMaskConnected = async () => {
        const accounts = await provider.listAccounts()
        return accounts.length > 0
      }

      await isMetaMaskConnected().then(connected => {
        if (connected) {
          console.log('MetamasK connected ')
        } else {
          console.log('MetamasK not connected yet')
        }
      })

      if (window.ethereum != null) {
        ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x61' }]
        })
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts'
        })
        if (accounts !== null) {
          var wall = accounts[0]
          // setAcc(wall)
          console.log('wallet address', wall)
          console.log(wall)
          setCookies('address', accounts[0])
        }
      }
    }
    fetch()
  }, [cookies.address, setCookies])
  return (
    <div className='App'>
      <div className='wrapper'>
        <Header />
        {/* <main> */}
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/product:id' exact element={<Product />} />
          <Route path='/tos' exact element={<Terms />} />
          <Route path='/privacy' exact element={<Privacy />} />
          {/* {ownerFlag === true ? ( */}
          <Route path='/createpool' exact element={<Createpool />} />
          <Route path='/admin/*' exact element={<Admin />}/>
          {/* )} */}
        </Routes>

        {/* </main> */}
        {/* <Footer /> */}
      </div>
    </div>
  )
}

export default App
