import { useEffect, useState, useCallback } from 'react'
import { createClient } from 'urql'
// import data from '../utils/api'
// import { ethers } from 'ethers'
function useFetchGraph () {
  const [dataG, setDataG] = useState([])
  
  const API_URL = process.env.REACT_APP_SUBGRAPH_API
  const fetchUpcoming = useCallback(async () => {
    var timestamp = await Math.round(+new Date() / 1000)

    const tokensQuery = `
    query{

    pools(where:{poolStartDate_gt: ${timestamp},  poolEndDate_gt:${timestamp} } ) 
    {
      poolingTokenAddress
      biddingTokenAddress
      poolId
      orderCancellationEndDate
      poolStartDate
      poolEndDate
      userId
      hash
      initialsupply
      tokenPrice
      tokenVestion
      hardCap
      swapRate
      minimumBiddingAmountPerOrder
      minFundingThreshold
      pooledSellAmount
      minBuyAmount
    }
  
  }
  
  
  `

    const client2 = createClient({
      url: API_URL
    })

    const data2 = await client2.query(tokensQuery).toPromise()

    // data2.then(res=>{
    //   console.log("responce form graph",res);
    // }).catch(err=>{
    //   console.log("Problem is Subgraph Check")
    // })
    console.log('Upcoming Pools', data2.data.pools)

    // if (data2.data !== null || data2.data !== undefined) {
    //   setDataG(data2.data.pools)

    // }
  }, [API_URL])
  const fetchEnded = useCallback(async () => {
    var timestamp = await Math.round(+new Date() / 1000)

    const tokensQuery = `
    query{

    pools(where:{poolStartDate_lt: ${timestamp},  poolEndDate_lt:${timestamp} } ) 
    {
      poolingTokenAddress
      biddingTokenAddress
      poolId
      orderCancellationEndDate
      poolStartDate
      poolEndDate
      userId
      hash
      initialsupply
      tokenPrice
      tokenVestion
      hardCap
      swapRate
      minimumBiddingAmountPerOrder
      minFundingThreshold
      pooledSellAmount
      minBuyAmount
    }
  
  }
  `

    const client2 = createClient({
      url: API_URL
    })

    const data2 = await client2.query(tokensQuery).toPromise()

    // data2.then(res=>{
    //   console.log("responce form graph",res);
    // }).catch(err=>{
    //   console.log("Problem is Subgraph Check")
    // })
    console.log('Ended Pools  ---', data2.data.pools)

    // if (data2.data !== null || data2.data !== undefined) {
    //   setDataG(data2.data.pools)

    // }
  }, [API_URL])
  const fetchLive = useCallback(async () => {
    var timestamp = await Math.round(+new Date() / 1000)

    const tokensQuery = `
    query{

    pools(where:{poolStartDate_lt: ${timestamp},  poolEndDate_gt:${timestamp} } ) 
    {
      poolingTokenAddress
      biddingTokenAddress
      poolId
      orderCancellationEndDate
      poolStartDate
      poolEndDate
      userId
      hash
      initialsupply
      tokenPrice
      tokenVestion
      hardCap
      swapRate
      minimumBiddingAmountPerOrder
      minFundingThreshold
      pooledSellAmount
      minBuyAmount
    }
  
  }
  
  
  `

    const client2 = createClient({
      url: API_URL
    })

    const data2 = await client2.query(tokensQuery).toPromise()

    // data2.then(res=>{
    //   console.log("responce form graph",res);
    // }).catch(err=>{
    //   console.log("Problem is Subgraph Check")
    // })
    console.log('Live Pools', data2.data.pools)
  }, [API_URL])

  const fetchGraphData = useCallback(async () => {
    const tokensQuery = `
      query{
          pools{
            poolingTokenAddress
            biddingTokenAddress
            poolId
            orderCancellationEndDate
            poolStartDate
            poolEndDate
            userId
            hash
            initialsupply
            tokenPrice
            tokenVestion
            hardCap
            swapRate
            minimumBiddingAmountPerOrder
            minFundingThreshold
            pooledSellAmount
            minBuyAmount
            
          }
          
      }
             `
    const client2 = createClient({
      url: API_URL
    })

    const data2 = await client2.query(tokensQuery).toPromise()

    // data2.then(res=>{
    //   console.log("responce form graph",res);
    // }).catch(err=>{
    //   console.log("Problem is Subgraph Check")
    // })
    console.log(' All graph data  ---', data2)

    if (data2.data !== null || data2.data !== undefined) {
      setDataG(data2.data.pools)
    }
    // setDataG()
  }, [API_URL])

  useEffect(() => {
    fetchEnded()
    fetchUpcoming()
    fetchLive()
    fetchGraphData()
  }, [fetchGraphData, fetchUpcoming, fetchEnded, fetchLive])
  return [dataG]
}
export default useFetchGraph
