import React from 'react'
import '../../assets/css/style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ProductInfo from './ProductInfo'
import Orderdetail from '../OrderDetails/orderdetail'
const ProductPage = () => {
  return (
    <>
      <ProductInfo />
      <Orderdetail />
    </>
  )
}
export default ProductPage
