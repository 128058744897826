import React from 'react'
import { useState, useEffect } from 'react'
import Logo from '../assets/images/logo.svg'
import Holdex from '../assets/images/holdex.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import useGetOwner from '../CustomHooks/GetOwner'
const Header = () => {
  const [acc, setAcc] = useState('Connect Wallet')
  const [cookies, setCookies, removeCookie] = useCookies(['address'])
  const [ownerFlag] = useGetOwner(cookies.address)

  useEffect(() => {
    window.ethereum.on('accountsChanged', accounts => {
      
      let selectedAccount = accounts[0]
      setCookies('address', selectedAccount)
      setAcc(converSubString(selectedAccount))
    })
    if (cookies.address !== undefined) {
      setAcc(converSubString(cookies.address))
    }

    if (cookies.address === 'undefined') {
      removeCookie('address')
      setAcc('Connect Wallet')
    }
  }, [cookies.address, acc, setCookies, ownerFlag, removeCookie])
  const converSubString = arg => {
    var res = arg.substring(0, 2)
    var res2 = arg.substring(38, 42)
    const wall = res + '...' + res2
    return wall
  }
  const walletConnect = async () => {
    try {
      const { ethereum } = window
      ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x61' }]
      })
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
      if (accounts !== null) {
        var wall = converSubString(accounts[0])
        setAcc(wall)
        setCookies('address', accounts[0])
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
      <>
          <header>
              <Navbar className="navbar navbar-expand-lg navbar-light" expand="lg">
                  <Container fluid className="custom-block">
                      <Navbar.Brand href="/">
                          <figure className="mb-0">
                              <img src={Logo} className="img-fluid" alt="SiteLogo" />
                          </figure>
                      </Navbar.Brand>

                      <Navbar.Toggle aria-controls="basic-navbar-nav">
                          <FontAwesomeIcon icon={faBars} />
                      </Navbar.Toggle>
                      <Navbar.Collapse className="collapse navbar-collapse" id="basic-navbar-nav">
                          <Nav className="m-auto navbar-nav">
                              {/* {ownerFlag === true ? ( */}
                              <div></div>
                             
                              <Link to="/admin/dashboard1" className="p-4 text-white">
                                  Admin Panel
                              </Link>

                              <Link to="createpool" className="p-4 text-white">
                                  Create Pool
                              </Link>
                              <Link to="/" className="p-4 text-white">
                                  Pools
                              </Link>
                              <Link to="faqs" className="p-4 text-white">
                                  FAQs
                              </Link>
                          </Nav>
                      </Navbar.Collapse>

                      <div className="block-btn">
                          <a href="https://linux.holdex.finance/swap" className="icon-btn shadow-btn text-capitalize">
                              <img src={Holdex} className=" img-fluid h-5" alt="currencylogo" />
                              Buy <span className="text-uppercase">Holdex</span>
                          </a>
                          <button className="d-inline light-blue-btn text-capitalize" onClick={walletConnect}>
                              {acc}
                          </button>
                      </div>
                  </Container>
              </Navbar>
          </header>
      </>
  );
}

export default Header
