import React from 'react'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { TailSpin } from 'react-loader-spinner'
import useFetchAllOrder from '../../../CustomHooks/FetchAllOrder'

function ManagePools () {
  console.log('hello')

  const [AllOrders] = useFetchAllOrder()

  console.log('we are in manage pools and all polls are ', AllOrders)
  return (
    <div>
      <div className='content'>
        <div className='manage-pools-pg'>
          <Card>
            <Card.Header className='titles-sett'>
              Manage Pools
              <button className='d-inline light-blue-btn text-capitalize'>
                <Link to='/createpool' className='text-white'>
                 
                <FontAwesomeIcon className='add-icon' icon={faPlus} />  Add Pool
                </Link>
              </button>
            </Card.Header>
            <Card.Body>
              {AllOrders.length > 0 ? (
                <>
                  <div className='table-responsive manage-pools '>
                    <Table>
                      <thead>
                        <tr>
                          <th>Pool #</th>
                          <th>Total Amount</th>
                          <th>Last Name</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {AllOrders.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.poolId}</td>
                              <td>{item.initialsupply}</td>
                              <td>{item.buyAmount}</td>
                              <td>{item.sellAmount}</td>
                              <td>
                                <FontAwesomeIcon
                                  className='del-icon'
                                  icon={faTrash}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
              ) : (
                <div className='text-center'>
                  <TailSpin
                    height='100'
                    width='100'
                    color='blue'
                    ariaLabel='loading'
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}
export default ManagePools
