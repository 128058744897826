import React, { useState, useRef, useEffect } from 'react'
// import DatePicker from "react-datepicker"

import 'react-datepicker/dist/react-datepicker.css'
import { Form, Button, Row, Modal, Accordion } from 'react-bootstrap'
import data from '../../utils/api'
import TokenAbi from '../../utils/token20Abi'
import './createpool.css'
import { create } from 'ipfs-http-client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import Info from '../InfoBtn/info'
import { ethers } from 'ethers'
import { useCookies } from 'react-cookie'
import ErrorLabels from '../ErrorLabels/errorlabels'
const client = create('https://ipfs.infura.io:5001/api/v0')
function Createpool () {
  //----------------------------------------------
  // ---------- All states appear Below ----------
  //----------------------------------------------

  const [cookies] = useCookies('address')
  const [acc, setAcc] = useState('')

  const idoAddress = process.env.REACT_APP_IDO_Address
  const [poolName, setPoolName] = useState('')
  const [bidName, setBidName] = useState('')
  const [poolBalErr, setPoolBalErr] = useState('')
  const [bidBalErr, setBidBalErr] = useState('')
  
  const [createCheck, setCreateCheck] = useState(false)
  var myRef = useRef(null)
  // const [btnActive, setBt  nActive] = useState(true)

  const [poolMeta, setPoolMeta] = useState({
    title: '',
    description: '',
    accessType: '',
    tokenType: '',
    tokenListing: '',
    distribution: '',
    // -----------------To generate hash
    poolingToken: '', //address
    biddingToken: '', //address
    accessManagerContract: '0x0000000000000000000000000000000000000000', //address
    orderCancellationEndDate: '', //date
    poolStartDate: '', //date
    poolEndDate: '', //date
    minimumBiddingAmountPerOrder: '',
    minFundingThreshold: 0,
    pooledSellAmount: '',
    minBuyAmount: '',
    isAtomicClosureAllowed: '',
    hash: '',
    accessManagerContractData: '',

    // -----------------
    website: '',
    logo: '',
    telegram: '',
    discord: '',
    medium: '',
    twitter: '',
    // --------------
    initialsupply: 0,
    tokenPrice: 0,
    tokenVestion: 0,
    hardCap: 0,
    swapRate: 0
    // ------------------
  })

  const [errors, setErrors] = useState({})
  // console.log(accessManagerContractData)
  // setting loader for token 1 approval
  const [apTok1, setApTok1] = useState(false)
  const [apTok2, setApTok2] = useState(false)
  const [apPool, setApPool] = useState(false)

  //state for modal handling
  const [modalShow, setModalShow] = React.useState(false)

  //----------------------------------------------
  // ---------- All states appear above ----------
  //----------------------------------------------
  useEffect(() => {
    if (cookies.address !== undefined) {
      setAcc(cookies.address)
    }

    if (cookies.address === 'undefined') {
      setAcc('Connect Wallet')
    }
    console.log(acc.getBalance)
  }, [cookies.address, acc])

  //----------------------------------------------
  // ----- All Event Handlers appear Below -------
  //----------------------------------------------
  const handleCreateCheck = async () => {
    handleValidation()
    setCreateCheck(true)
    myRef.current.scrollIntoView()
    console.log('myRef', myRef)
    
  }
  const handleChange = async e => {
    const { name, value } = e.target
    if (
      name === 'poolEndDate' ||
      name === 'poolStartDate' ||
      name === 'orderCancellationEndDate'
    ) {
      console.log('dates', value)
      setPoolMeta({ ...poolMeta, [name]: value })
    }
    if (name === 'logo') {
      const file = e.target.files[0]
      console.log(file)
      const logoUrl = await client.add(file)
      const url = `https://ipfs.infura.io/ipfs/${logoUrl.path}`
      console.log(url)
      setPoolMeta({ ...poolMeta, logo: url })
    }
    if (name === 'poolingToken') {
      console.log('We are in poolin gtoken')
      setPoolMeta({ ...poolMeta, [name]: value })
      // if (ethers.utils.isAddress(value) && value != '' && value != null) {
      setPoolName('')

      const contract = await FetchProvider(value, TokenAbi)

      console.log(contract)
      if (contract !== undefined) {
        setErrors({ ...errors, poolingToken: '' })
        console.log('start')
        setPoolName('loading')
        contract
          .name()
          .then(res => {
            console.log('hello', res)
            if (res !== bidName) {
              setPoolName(res)
            } else {
              setErrors({
                ...errors,
                poolingToken: 'Both tokens address cannot be same'
              })
            }
            console.log('end')
          })
          .catch(err => {
            setPoolName('')
            setErrors({
              ...errors,
              poolingToken: 'Enter valid BEP20 token address'
            })
          })
      } else {
        errors.poolingToken = 'Enter Valid address'
      }
      // }
      // handleValidation()
    }
    if (name === 'biddingToken') {
      console.log('We are in Bidding gtoken')
      setPoolMeta({ ...poolMeta, [name]: value })
      // if (ethers.utils.isAddress(value) && value != '' && value != null) {
      const contract = await FetchProvider(value, TokenAbi)

      console.log(contract)
      setBidName('')
      if (contract !== undefined) {
        setErrors({ ...errors, biddingToken: '' })
        console.log('start')
        setBidName('loading')
        contract
          .name()
          .then(res => {
            console.log('hello', res)
            if (res !== poolName) {
              setBidName(res)
            } else {
              setErrors({
                ...errors,
                biddingToken: 'Both tokens address cannot be same'
              })
            }
            console.log('end')
          })
          .catch(err => {
            setBidName('')
            setErrors({
              ...errors,
              biddingToken: 'Enter valid BEP20 token address'
            })
          })
      } else {
        setErrors({
          ...errors,
          biddingToken: 'Enter valid address'
        })
      }
      // }
      // handleValidation()
    } else {
      // handleValidation()
      setPoolMeta({ ...poolMeta, [name]: value })
    }
    // handleValidation()
  }
  //add validations to fieds to catch errors
  const handleValidation = async () => {
    const {
      title,
      // description,
      accessType,
      // accessManagerContract,
      // accessManagerContractData,
      initialsupply,
      tokenType,
      tokenVestion,
      distribution,
      // tokenPrice,
      tokenListing,
      // hardCap,
      // swapRate,
      poolStartDate,
      poolEndDate,
      poolingToken,
      biddingToken,
      orderCancellationEndDate,
      minimumBiddingAmountPerOrder,
      minBuyAmount,
      minFundingThreshold,
      website,
      telegram,
      discord,
      medium,
      twitter,
      pooledSellAmount
    } = poolMeta
    var newErrors = {}
    console.log('check value is ', createCheck)
    if (title === '') {
      newErrors.title = 'Please enter title value'
    }
    // if (description === '') {
    //   newErrors.description = 'Please Enter description'
    // }
    if (accessType === '') {
      newErrors.accessType = 'Please enter access type'
    }
    if (tokenType === '') {
      newErrors.tokenType = 'Please enter token type'
    }
    if (tokenListing === '') {
      newErrors.tokenListing = 'Please enter token listing'
    }
    if (distribution === '') {
      newErrors.distribution = 'Please enter token distribution details'
    }
    if (initialsupply === 0) {
      newErrors.initialsupply = 'Please define token initial supply'
    }
    // if (tokenPrice === 0) {
    //   newErrors.tokenPrice = 'Please Define token Price'
    // }
    if (minFundingThreshold === 0 || minFundingThreshold === '') {
      newErrors.minFundingThreshold = 'Please define min funding details'
    }
    if (tokenVestion === 0) {
      newErrors.tokenVestion = 'Please define vestion details'
    }
    // if (swapRate === 0) {
    //   newErrors.swapRate = 'please define swap rate '
    // }
    // if (hardCap === 0) {
    //   newErrors.hardCap = 'please define hard Cap '
    // }
    const poolStartDate1 = await Math.floor(
      new Date(parseInt(poolStartDate)).getTime() / 1000
    )
    const poolEndDate1 = await Math.floor(
      new Date(parseInt(poolEndDate)).getTime() / 1000
    )
    const orderCancellationEndDate1 = await Math.floor(
      new Date(parseInt(orderCancellationEndDate)).getTime() / 1000
    )

    console.log(
      'start',
      poolStartDate1,
      'EndDate',
      poolEndDate1,
      'orderCancellation',
      orderCancellationEndDate1
    )
    console.log('start date', poolStartDate1)
    if (poolStartDate1 <= 0 || poolStartDate1 > poolEndDate1) {
      console.log('start date123', poolStartDate)
      newErrors.poolStartDate =
        'Start date less than end date and greater current time'
    }

    if (poolEndDate1 <= 0 || poolEndDate1 < poolStartDate1) {
      newErrors.poolEndDate =
        ' Pool end date should greater than start date and current time'
    }
    if (
      orderCancellationEndDate1 <= 0 ||
      orderCancellationEndDate1 < poolStartDate1
    ) {
      newErrors.orderCancellationEndDate =
        ' Order cancel date should less/equal end date'
    }
    if (!ethers.utils.isAddress(poolingToken)) {
      console.log('pooling token', poolingToken)
      newErrors.poolingToken = ' Enter valid pooling token address'
    }

    // if (!ethers.utils.isAddress(accessManagerContract)) {
    //   newErrors.accessManagerContract =
    //     ' Enter Valid Access manager contract Address '
    // }

    // if (accessManagerContractData === '') {
    //   newErrors.accessManagerContractData =
    //     ' Enter Pooling access manager contract data'
    // }

    if (!ethers.utils.isAddress(biddingToken)) {
      newErrors.biddingToken = ' Enter valid bidding token address'
    }
    if (pooledSellAmount === '') {
      newErrors.pooledSellAmount = ' Enter pooling sell Amount'
    }
    if (minBuyAmount === '') {
      newErrors.minBuyAmount = ' Enter pooling minimum buy amount'
    }

    if (minimumBiddingAmountPerOrder === '') {
      newErrors.minimumBiddingAmountPerOrder =
        ' Enter minimum bidding amount per order'
    }

    const regex = new RegExp(
      '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
    )
    if (!regex.test(website)) {
      newErrors.website = 'Enter valid website link'
    }
    if (!regex.test(telegram)) {
      newErrors.telegram = 'Enter valid telegram link'
    }

    if (!regex.test(twitter)) {
      newErrors.twitter = 'Enter valid twitter link'
    }

    if (!regex.test(medium)) {
      newErrors.medium = 'Enter valid medium link'
    }

    if (!regex.test(discord)) {
      newErrors.discord = 'Enter valid discord link'
    }

    console.log(Object.keys(newErrors).length)
    if (
      Object.keys(newErrors).length > 0 ||
      poolName === '' ||
      poolName === 'loading' ||
      bidName === '' ||
      bidName === 'loading'
    ) {
      setErrors(newErrors)
      setModalShow(false)
      return false
    } else {
      // setBtnActive(true)
      setCreateCheck(true)

      return true
    }
  }

  //Function to initialize pool
  const GetHash = async e => {
    // e.preventDefault()

    var contract = await FetchProvider(idoAddress, data)

    const {
      poolingToken,
      biddingToken,
      accessManagerContract,
      orderCancellationEndDate,
      poolStartDate,
      poolEndDate,
      minimumBiddingAmountPerOrder,
      minFundingThreshold,
      minBuyAmount,
      pooledSellAmount,
      tokenVestion,
      tokenPrice,
      swapRate,
      initialsupply,
      hardCap
      // logo
    } = poolMeta

    //setting dates in required UNIX format.otherwise throw error.
    const poolStartDate1 = await Math.floor(
      new Date(poolStartDate).getTime() / 1000
    )
    const poolEndDate1 = await Math.floor(
      (await new Date(poolEndDate).getTime()) / 1000
    )
    const orderCancellationEndDate1 = await Math.floor(
      (await new Date(orderCancellationEndDate).getTime()) / 1000
    )

    client.add(JSON.stringify(poolMeta)).then(async res => {
      setPoolMeta({ ...poolMeta, hash: res })
      var owner = await contract.initiatePool([
        poolingToken,
        biddingToken,
        accessManagerContract,
        orderCancellationEndDate1,
        poolStartDate1,
        poolEndDate1,
        true,
        '0x',
        res.path,
        [
          parseInt(initialsupply),
          parseInt(tokenPrice),
          parseInt(tokenVestion),
          parseInt(hardCap),
          parseInt(swapRate),
          parseInt(minimumBiddingAmountPerOrder),
          parseInt(minFundingThreshold),
          parseInt(pooledSellAmount),
          parseInt(minBuyAmount)
        ]
      ])
      setApPool(true)
      console.log(owner)
      const url = `https://ipfs.infura.io/ipfs/${res.path}`
      console.log(url)
    })
  }
  // function to fetch Provider and signer
  const FetchProvider = async (tokenAdd, Abi) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    console.log('signer', signer)
    if (ethers.utils.isAddress(tokenAdd)) {
      var address = tokenAdd
      var contract = new ethers.Contract(address, Abi, signer)
      console.log(contract)
      return contract
    } else {
      console.log('You Enter Invalid Address')
    }
  }
  console.log('errors----', errors)
  //function to approve Polling Token
  const ApprovePollingToken = async () => {
    const validate = await handleValidation()

    const { poolingToken } = poolMeta

    if (validate) {
      setModalShow(true)
      const contract = await FetchProvider(poolingToken, TokenAbi)
      // -------------------------
      // Checking for allowance --
      // -------------------------
      console.log('pooling token address', poolingToken)
      const balanPool = parseInt(await contract.balanceOf(acc))

      console.log('balnce', balanPool)
      if (balanPool > 0) {
        setPoolBalErr('')
        console.log(`You have ${poolName} balance `, balanPool)
        const allowance = await contract.allowance(acc, idoAddress)
        console.log('allowance ', parseInt(allowance))

        if (parseInt(allowance) <= 0) {
          //if not allowance then go for approve

          contract
            .approve(idoAddress, 1000000000000000)
            .then(res => {
              setApTok1(true)
              ApproveBiddingToken()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          console.log('Already allowanced')
          setApTok1(true)
          ApproveBiddingToken()
        }
      } else {
        console.log(`You dont have ${poolName} Enough balance`)

        setPoolBalErr(`You dont have ${poolName} Enough balance`)
      }
    } else {
      console.log('Resolve Errors')
    }
  }

  //function to approve Bidding Token Token
  const ApproveBiddingToken = async () => {
    const { biddingToken } = poolMeta

    if (biddingToken !== '') {
      setApTok1(true)
      var contract = await FetchProvider(biddingToken, TokenAbi)

      console.log(contract)

      // --------------------------
      // Check token allowance here
      // --------------------------
      const balanBid = await contract.balanceOf(acc)

      if (parseInt(balanBid) > 0) {
        setBidBalErr('')
        const allowance = await contract.allowance(acc, idoAddress)
        console.log(parseInt(allowance))

        console.log(`You have ${bidName} balance `, balanBid)
        if (parseInt(allowance) <= 0) {
          //if not allowance then go for approve
          contract
            .approve(idoAddress, 1000000000000000)
            .then(res => {
              console.log(res)

              setApTok2(true)
              GetHash()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          console.log('Bidding token already allowanced')
          setApTok2(true)
          GetHash()
        }
      } else {
        setBidBalErr(`You dont have ${bidName} Enough balance`)
        console.log(`You dont have ${bidName} Enough balance`)
      }
    } else {
      console.log('resolve errors')
    }
  }
  const handleImgError = ev => {}
  return (
    <>
      <div className='container'>
        <div className='page-heading'>
          <h2 className='text-white  mt-4'>Create Pool</h2>
        </div>
        <div className='heading'>
          <h3 className='text-white  mt-4'>Pool Details</h3>
        </div>
        <hr className='text-white'></hr>
        <Form
          className='create-pool text-white'
          onKeyUp={createCheck ? handleValidation : null}
        >
          <Row>
            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='title'>
                <Info
                  name={'Title'}
                  desc={'Title Should be in String amd max 50 letter'}
                />
                <Form.Control
                  type='text'
                  ref={myRef}
                  placeholder='Enter Title of Token'
                  value={poolMeta.title}
                  name='title'
                  onChange={handleChange}
                  isInvalid={!!errors.title}
                />
                <ErrorLabels props={errors.title} />
              </Form.Group>

              <Form.Group className='mb-4 cms-pages-style' controlId='description'>
                <Info
                  name={'Description'}
                  desc={'Description should be general and brief'}
                />
                <Form.Control
                  as="textarea"
                  rows={3}
                  name='description'
                  value={poolMeta.description}
                  placeholder='Enter Description'
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className='mb-4' controlId='tokenListing'>
                <Info
                  name={'Token Listing'}
                  desc={'Enter Token Listing Details e.g Binance , Houbi etc'}
                />
                <Form.Control
                  type='text'
                  name='tokenListing'
                  onChange={handleChange}
                  value={poolMeta.tokenListing}
                  placeholder='Listing '
                  isInvalid={!!errors.tokenListing}
                />

                <Form.Control.Feedback type='invalid'>
                  {errors.tokenListing}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='accessType'>
                <Info
                  name={'Access Type'}
                  desc={'Enter brief and genral Details about the project'}
                />
                <Form.Control
                  type='text'
                  name='accessType'
                  onChange={handleChange}
                  value={poolMeta.accessType}
                  isInvalid={!!errors.accessType}
                  placeholder='Access Type of Token'
                />
                <ErrorLabels props={errors.accessType} />
              </Form.Group>

              <Form.Group className='mb-4' controlId='tokenType'>
                <Info
                  name={'Token Type'}
                  desc={'Enter Type of Token e.g ERC20,BEP20,CW20'}
                />
                <Form.Control
                  name='tokenType'
                  onChange={handleChange}
                  value={poolMeta.tokenType}
                  type='text'
                  isInvalid={!!errors.tokenType}
                  placeholder='Token Type'
                />
                <ErrorLabels props={errors.tokenType} />
              </Form.Group>
              <Form.Group className='mb-4' controlId='distribution'>
                <Info
                  name={' Token Distribution'}
                  desc={'How token will distribute among team'}
                />
                <Form.Control
                  type='text'
                  name='distribution'
                  onChange={handleChange}
                  value={poolMeta.distribution}
                  isInvalid={!!errors.distribution}
                  placeholder='Token Distribution'
                />

                <ErrorLabels props={errors.distribution} />
              </Form.Group>
            </div>
          </Row>
          <div className='heading'>
            <h3 className='text-white  mt-4'>Uploads</h3>
          </div>
          <hr className='text-white mb-5'></hr>

          <Row>
            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='logo'>
                <Form.Label>Upload Logo</Form.Label>
                <Form.Control
                  type='file'
                  onChange={handleChange}
                  name='logo'
                  placeholder='Upload Logo'
                />
              </Form.Group>
            </div>
            {poolMeta.logo !== '' ? (
              <div className='col-md-6 '>
                <img
                  src={poolMeta.logo}
                  alt='logo'
                  className='img-fluid'
                  width='100'
                  height='100'
                  onError={handleImgError}
                ></img>
              </div>
            ) : (
              <></>
            )}
          </Row>

          {/*-------------------- Tokennomics------------- */}
          <div className='heading'>
            <h3 className='text-white  mt-4'>Tokenomics</h3>
          </div>
          <hr className='text-white mb-5'></hr>

          <Row>
            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='initialsupply'>
                <Info
                  name='Initial Supply '
                  desc='Enter Initial supply in Number e.g 100000'
                />
                <Form.Control
                  type='number'
                  value={poolMeta.initialsupply}
                  onChange={handleChange}
                  name='initialsupply'
                  isInvalid={!!errors.initialsupply}
                  placeholder='Enter initial supply of coin'
                />
                <ErrorLabels props={errors.initialsupply} />
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='tokenVestion'>
                <Info
                  name='Token Vestion'
                  desc={
                    'How much percentage of coin will be distributed among.g 1.2 ,25'
                  }
                />
                <Form.Control
                  type='number'
                  value={poolMeta.tokenVestion}
                  onChange={handleChange}
                  name='tokenVestion'
                  placeholder='Token Vestion %'
                  isInvalid={!!errors.tokenVestion}
                />
                <ErrorLabels props={errors.tokenVestion} />
              </Form.Group>
            </div>
          </Row>
          {/*-------------- Date--------------- */}
          <div className='heading'>
            <h3 className='text-white  mt-4'>Date</h3>
          </div>
          <hr className='text-white mb-5'></hr>

          <Row>
            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='poolStartDate'>
                <Info
                  name={'Listing Date'}
                  desc={'Enter Date When IDO will Open'}
                />
                <Form.Control
                  type='date'
                  value={poolMeta.poolStartDate}
                  onChange={handleChange}
                  name='poolStartDate'
                  isInvalid={!!errors.poolStartDate}
                  placeholder='Enter Pool Start Date'
                />
                <ErrorLabels props={errors.poolStartDate} />
              </Form.Group>
              <Form.Group className='mb-4' controlId='orderCancellationEndDate'>
                <Info
                  name={'Order Cancel Date'}
                  desc={'Till which date you can cancel your order'}
                />

                <Form.Control
                  type='date'
                  onChange={handleChange}
                  name='orderCancellationEndDate'
                  isInvalid={!!errors.orderCancellationEndDate}
                  value={poolMeta.orderCancellationEndDate}
                  placeholder='Enter Order Canncel End Date'
                />
                <ErrorLabels props={errors.orderCancellationEndDate} />
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='poolEndDate'>
                <Info name={'IDO end Date'} desc={'Enter when IDO will End'} />
                <Form.Control
                  type='date'
                  value={poolMeta.poolEndDate}
                  onChange={handleChange}
                  name='poolEndDate'
                  placeholder='Enter End Date'
                  isInvalid={!!errors.poolEndDate}
                />

                <ErrorLabels props={errors.poolEndDate} />
              </Form.Group>
            </div>
          </Row>

          {/* --------------Token Listing Details------------- */}

          <div className='heading'>
            <h3 className='text-white  mt-4'>Token Lisiting Details</h3>
          </div>
          <hr className='text-white mb-5'></hr>

          <Row>
            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='poolingToken'>
                <Info
                  name={'Pooling Token'}
                  desc={'Enter Address of pooling token in hex form e.g 0x....'}
                />
                {poolName === '' ? (
                  <></>
                ) : poolName === 'loading' ? (
                  <p className='text-white text-end float-end'>
                    <img
                      src='https://i.stack.imgur.com/qq8AE.gif'
                      alt='loader'
                      style={{ width: '15%' }}
                    />
                  </p>
                ) : (
                  <p className='text-white text-end float-end'>{poolName}</p>
                )}

                <Form.Control
                  type='text'
                  value={poolMeta.poolingToken}
                  onChange={handleChange}
                  name='poolingToken'
                  isInvalid={!!errors.poolingToken}
                  placeholder='Paste pooling token address'
                />
                <ErrorLabels props={errors.poolingToken} />
              </Form.Group>

              <Form.Group className='mb-4' controlId='biddingToken'>
                <Info
                  name={'Bidding Token'}
                  desc={'Enter Address of Bidding in hex form e.g 0x....'}
                />
                {bidName === '' ? (
                  <></>
                ) : bidName === 'loading' ? (
                  <p className='text-white text-end float-end'>
                    <img
                      src='https://i.stack.imgur.com/qq8AE.gif'
                      style={{ width: '15%' }}
                      alt='loader'
                    />
                  </p>
                ) : (
                  <p className='text-white text-end float-end'>{bidName}</p>
                )}
                <Form.Control
                  type='text'
                  value={poolMeta.biddingToken}
                  onChange={handleChange}
                  name='biddingToken'
                  isInvalid={!!errors.biddingToken}
                  placeholder='Paste Bidding token address'
                />
                <ErrorLabels props={errors.biddingToken} />
              </Form.Group>

              <Form.Group className='mb-4' controlId='minFundingThreshold'>
                <Info
                  name={'Minimum Funding Threshold'}
                  desc={'How much token you want to collect....'}
                />
                <Form.Control
                  type='number'
                  value={poolMeta.minFundingThreshold}
                  onChange={handleChange}
                  name='minFundingThreshold'
                  placeholder='Enter How much token You want to get in return'
                  isInvalid={!!errors.minFundingThreshold}
                />

                <ErrorLabels props={errors.minFundingThreshold} />
              </Form.Group>
              {/* <Form.Group className='mb-4' controlId='formBasicEmail'>
                <Form.Label className='required'>Minimum Funding Threshold</Form.Label>
                <Form.Control
                  type='text'
                  name='accessManagerContract'
                  value={poolMeta.accessManagerContract}
                  onChange={handleChange}
                  isInvalid={!!errors.accessManagerContract}
                  placeholder='Enter Access manager contract address'
                />

                <ErrorLabels props={errors.accessManagerContract} />
              </Form.Group> */}
            </div>
            <div className='col-md-6'>
              <Form.Group
                className='mb-4'
                controlId='minimumBiddingAmountPerOrder'
              >
                <Info
                  name={'Minimum bidding Amount'}
                  desc={'Minimum amount A user can bid on IDO'}
                />
                <Form.Control
                  type='number'
                  value={poolMeta.minimumBiddingAmountPerOrder}
                  onChange={handleChange}
                  name='minimumBiddingAmountPerOrder'
                  isInvalid={!!errors.minimumBiddingAmountPerOrder}
                  placeholder='Enter Minimum Bidding Amount Per Order'
                />
                <ErrorLabels props={errors.minimumBiddingAmountPerOrder} />
              </Form.Group>
              {/* <Form.Group className='mb-4' controlId='formBasicEmail'>
                <Form.Label className='required'>
                  Manager Contract Data
                </Form.Label>
                <Form.Control
                  type='text'
                  name='accessManagerContractData'
                  value={poolMeta.accessManagerContractData}
                  onChange={handleChange}
                  isInvalid={!!errors.accessManagerContractData}
                  placeholder='Enter Access manager contract address'
                />
                <ErrorLabels props={errors.accessManagerContractData} />
              </Form.Group> */}

              <Form.Group className='mb-4' controlId='pooledSellAmount'>
                <Info
                  name={'Pool Sell Amount'}
                  desc={'How much Token You have to Offer in IDO'}
                />
                <Form.Control
                  type='number'
                  value={poolMeta.pooledSellAmount}
                  onChange={handleChange}
                  name='pooledSellAmount'
                  isInvalid={!!errors.pooledSellAmount}
                  placeholder='Set pooled sell amount'
                />
                <ErrorLabels props={errors.pooledSellAmount} />
              </Form.Group>
              <Form.Group className='mb-4' controlId='formBasicEmail'>
                <Info
                  name={'Minimum Buy Amount'}
                  desc={'How much min token you can buy'}
                />
                <Form.Control
                  type='number'
                  value={poolMeta.minBuyAmount}
                  onChange={handleChange}
                  isInvalid={!!errors.minBuyAmount}
                  name='minBuyAmount'
                  placeholder='Enter Minimum Buy Amount'
                />
                <ErrorLabels props={errors.minBuyAmount} />
              </Form.Group>
            </div>
          </Row>
          {/*------------- Socials----------------- */}
          <div className='heading'>
            <h3 className='text-white  mt-4'>Other Details</h3>
          </div>
          <hr className='text-white mb-5'></hr>

          <Row>
            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='twitter'>
                <Form.Label>Twitter</Form.Label>
                <Form.Control
                  type='url'
                  value={poolMeta.twitter}
                  onChange={handleChange}
                  name='twitter'
                  placeholder='Twitter Link'
                  isInvalid={!!errors.twitter}
                />

                <ErrorLabels props={errors.twitter} />
              </Form.Group>

              <Form.Group className='mb-4' controlId='medium'>
                <Form.Label>Medium</Form.Label>
                <Form.Control
                  type='url'
                  value={poolMeta.medium}
                  onChange={handleChange}
                  name='medium'
                  placeholder='Medium Link'
                  isInvalid={!!errors.medium}
                />

                <ErrorLabels props={errors.medium} />
              </Form.Group>
              <Form.Group className='mb-4' controlId='formBasicEmail'>
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type='url'
                  value={poolMeta.website}
                  onChange={handleChange}
                  name='website'
                  placeholder='Website Link'
                  isInvalid={!!errors.website}
                />

                <ErrorLabels props={errors.website} />
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className='mb-4' controlId='formBasicEmail'>
                <Form.Label>Discord</Form.Label>
                <Form.Control
                  type='url'
                  value={poolMeta.discord}
                  onChange={handleChange}
                  name='discord'
                  placeholder='Discord Link'
                  isInvalid={!!errors.discord}
                />

                <ErrorLabels props={errors.discord} />
              </Form.Group>

              <Form.Group className='mb-4' controlId='formBasicEmail'>
                <Form.Label>Telegram</Form.Label>
                <Form.Control
                  type='url'
                  value={poolMeta.telegram}
                  onChange={handleChange}
                  name='telegram'
                  placeholder='Telegram Link'
                  isInvalid={!!errors.telegram}
                />

                <ErrorLabels props={errors.telegram} />
              </Form.Group>
            </div>
          </Row>

          {/*------------ tokennomics---------- */}
          <div className='btn-wrapper'>
            <Button
              // type='submit'
              className='light-blue-btn p-3 w-10'
              // onClick={GetHash}

              onClick={createCheck ? ApprovePollingToken : handleCreateCheck}
            >
              Create Pool
            </Button>
            {/* {handleValidation ? (
              <p className='text-danger pt-3'>Check for Errors</p>
            ) : (
              <></>
            )} */}
          </div>
        </Form>
      </div>
      {modalShow ? (
        <Modal
          className='pool-modal'
          show={modalShow}
          onHide={() => setModalShow(false)}
          // size="md"
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Create Auction
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="loader">
              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div> */}

            <Accordion>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>
                  <div className={apTok1 ? 'icon' : 'icon pending'}>
                    <FontAwesomeIcon icon={apTok1 ? faCheck : faExclamation} />
                    {apTok1 ? (
                      ''
                    ) : (
                      <div className='loader'>
                        <div class='lds-ring'>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    )}
                  </div>
                  {poolBalErr === '' ? (
                    <>Approve {poolName} Token</>
                  ) : (
                    <p className='text-danger p-2'>{poolBalErr}</p>
                  )}
                </Accordion.Header>
                {apTok1 ? (
                  <></>
                ) : (
                  <Accordion.Body className='hide'>
                    <div className='content'>
                      <>
                        <p>
                          This transaction is conducted only once per collection
                        </p>

                        <button
                          className='blue-outline-btn'
                          onClick={ApprovePollingToken}
                        >
                          Approve Pooling Token
                        </button>
                      </>
                    </div>
                  </Accordion.Body>
                )}
              </Accordion.Item>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>
                  <div className={apTok1 ? 'icon' : 'icon pending'}>
                    <FontAwesomeIcon icon={apTok2 ? faCheck : faExclamation} />
                    {apTok2 ? (
                      ''
                    ) : (
                      <div className='loader'>
                        <div class='lds-ring'>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    )}
                  </div>
                  {bidBalErr === '' ? (
                    <>Approve {bidName} Token</>
                  ) : (
                    <p className='text-danger p-2'>{bidBalErr}</p>
                  )}
                </Accordion.Header>
                {apTok2 ? (
                  <></>
                ) : (
                  <Accordion.Body>
                    <div className='content'>
                      {apTok1 ? (
                        <button
                          className='blue-outline-btn'
                          onClick={ApproveBiddingToken}
                        >
                          Approve Bidding Token
                        </button>
                      ) : (
                        <>
                          <p>First Approve Token 1</p>
                        </>
                      )}
                    </div>
                  </Accordion.Body>
                )}
              </Accordion.Item>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>
                  <div className={apTok1 ? 'icon' : 'icon pending'}>
                    <FontAwesomeIcon icon={apPool ? faCheck : faExclamation} />
                    {apPool ? (
                      ''
                    ) : (
                      <div className='loader'>
                        <div class='lds-ring'>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    )}
                  </div>
                  Pool Creation
                </Accordion.Header>
                <Accordion.Body>
                  <div className='content'>
                    <p>Uploading Pool Data To blockchain</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer className='text-center'>
            <Button
              variant='primary'
              onClick={() => {
                setModalShow(false)
              }}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}

export default Createpool
