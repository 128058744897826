import { useEffect, useState, useCallback } from 'react'
import { createClient } from 'urql'
function useFetchOrders (poolId) {
  const [orderData, setOrderData] = useState([])
  const API_URL = process.env.REACT_APP_SUBGRAPH_API

  const fetchOrder = useCallback(async () => {
    const tokensQuery = `
        query{
            sellOrders{
                poolId
                userId
                buyAmount
                sellAmount
              }
        }  
      `

    const client = createClient({
      url: API_URL
    })

    const data2 = await client.query(tokensQuery).toPromise()

    // data2.then(res=>{
    //   console.log("responce form graph",res);
    // }).catch(err=>{
    //   console.log("Problem is Subgraph Check")
    // })
    if (data2.data.sellOrders !== undefined) {
      console.log('Upcoming Orders', data2.data.sellOrders)
      setOrderData(data2.data.sellOrders)
    }
  }, [API_URL])
  useEffect(() => {
    fetchOrder()
  }, [fetchOrder])

  return [orderData]
}

export default useFetchOrders
