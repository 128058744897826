import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './landing.css'
// import Arenum from '../../assets/images/arenum.png'
import Icon1 from '../../assets/images/icon-1.png'
import { Oval } from 'react-loader-spinner'
import Icon2 from '../../assets/images/icon-2.png'
import Twitter from '../../assets/images/twiiter.png'
import Icon4 from '../../assets/images/icon-4.png'
import axios from 'axios'
import { Nav, Tab } from 'react-bootstrap'
import useFetchGraph from '../../CustomHooks/FetchGraph'
// import { ethers } from 'ethers'
const MultiChainCards = () => {
  //Custom hook for fetching data from subgraph

  const [dataG] = useFetchGraph()
  console.log(dataG);
  const socialLinks = [Icon1, Icon2, Twitter, Icon4]
  const [time] = useState({})
  const [upComArr, setUpComArr] = useState([])
  const [EndArr, setEndArr] = useState([])
  const [LiveArr,setLiveArr]=useState([]);
  const timeConverter = useCallback(
    UNIX_timestamp => {
      // var a = new Date(UNIX_timestamp * 1000);
      var a = new Date(UNIX_timestamp * 1000)
      var months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      var year = a.getFullYear()
      var month = months[a.getMonth()]
      var date = a.getDate()
      var hour = a.getHours()
      var min = a.getMinutes()
      var sec = a.getSeconds()
      var time1 =
        date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec
      time.month = month
      time.hour = hour
      time.min = min
      time.date = date
      // console.log('time', time)
      return time1
    },
    [time]
  )
  const ParseData = async arg => {
  
    const url = await axios.get(`https://ipfs.infura.io/ipfs/${arg}`)
    return url
  }
  const addDefaultImg = ev => {
    ev.target.src =
      'https://media.istockphoto.com/photos/abstract-graphic-world-map-illustration-on-blue-background-big-data-picture-id1294021851'
  }
  const GetRemainDays = async arg => {
    var unix = await Math.round(+new Date() / 1000)
    // console.log(unix)
    var date1 = unix
    var date2 = arg

    // To calculate the time difference of two dates
    var Difference_In_Time = date2 - date1
    var remainHour = {}
    var days = Math.floor(Difference_In_Time / 86400)
    var hours = Math.floor(days / 60)
    if (days < 0) {
      remainHour.day = 0
      remainHour.hours = 0
    } else {
      remainHour.day = days
      remainHour.hours = hours
    }
    return remainHour
  }
  const fetchData = useCallback(async () => {
    let dummyUpArr = []
    let dummyEndArr = []
    let dummyLive=[];
    if (dataG.length > 0) {
      for (let i = 0; i < dataG.length; i++) {
        const {
          poolingTokenAddress,
          biddingTokenAddress,
          poolId,
          // orderCancellationEndDate,
          poolStartDate,
          poolEndDate,
          // userId,
          hash,
          initialsupply,
          tokenPrice,
          tokenVestion,
          // hardCap,
          swapRate,
          // minimumBiddingAmountPerOrder,
          minFundingThreshold,
          pooledSellAmount,
          minBuyAmount
        } = dataG[i]

        // Data Parsing and Conversion appear here
        var timestamp = await Math.round(+new Date() / 1000)
        const timinStart = timeConverter(poolStartDate)
        const timinEnd = timeConverter(poolEndDate)
      
        const remainHour = await GetRemainDays(poolEndDate)
        const hashData = await ParseData(hash)
        // console.log('hash Data', hashData)
        const {
          title,
          description,
          accessType,
          tokenType,
          logo
        } = hashData.data
        if (poolEndDate > timestamp && poolStartDate>timestamp) {
          let dummyIdo = {}

          dummyIdo = {
            name: title,
            logo: logo,
            description: description,
            accessType: accessType,
            poolId: poolId,
            hardCap: minFundingThreshold,
            _poolingToken: poolingTokenAddress,
            _biddingToken: biddingTokenAddress,
            price: tokenPrice,
            tokenVestion: tokenVestion,
            minBuyAmount: minBuyAmount,
            initialSupply: initialsupply,
            startDate: timinStart,
            _pooledSellAmount: parseInt(pooledSellAmount),
            endDate: timinEnd,
            tokenType: tokenType,
            swapRate:
              parseInt(minFundingThreshold) / parseInt(pooledSellAmount),
            swapRate2: 1 / swapRate,
            remainTime: remainHour,
            status: 'open'
          }
          dummyUpArr.push(dummyIdo)
        } else if (poolEndDate < timestamp && poolStartDate<timestamp) {
          let dummyIdoEnd = {}

          dummyIdoEnd= {
            name: title,
            logo: logo,
            description: description,
            accessType: accessType,
            poolId: poolId,
            hardCap: minFundingThreshold,
            _poolingToken: poolingTokenAddress,
            _biddingToken: biddingTokenAddress,
            price: tokenPrice,
            tokenVestion: tokenVestion,
            minBuyAmount: minBuyAmount,
            initialSupply: initialsupply,
            startDate: timinStart,
            _pooledSellAmount: parseInt(pooledSellAmount),
            endDate: timinEnd,
            tokenType: tokenType,
            swapRate:
              parseInt(minFundingThreshold) / parseInt(pooledSellAmount),
            swapRate2: 1 / swapRate,
            remainTime: remainHour,
            status: 'close'
          }

          dummyEndArr.push(dummyIdoEnd)
        }
        else if(poolStartDate <timestamp && poolEndDate>timestamp ){
          let dummyIdoLive = {}

          dummyIdoLive= {
            name: title,
            logo: logo,
            description: description,
            accessType: accessType,
            poolId: poolId,
            hardCap: minFundingThreshold,
            _poolingToken: poolingTokenAddress,
            _biddingToken: biddingTokenAddress,
            price: tokenPrice,
            tokenVestion: tokenVestion,
            minBuyAmount: minBuyAmount,
            initialSupply: initialsupply,
            startDate: timinStart,
            _pooledSellAmount: parseInt(pooledSellAmount),
            endDate: timinEnd,
            tokenType: tokenType,
            swapRate:
              parseInt(minFundingThreshold) / parseInt(pooledSellAmount),
            swapRate2: 1 / swapRate,
            remainTime: remainHour,
            status: 'open'
          }

          dummyLive.push(dummyIdoLive)
        }
      }

      setUpComArr(dummyUpArr)
      setEndArr(dummyEndArr);
      setLiveArr(dummyLive)
    }
  }, [timeConverter, dataG])
  useEffect(() => {
    if (dataG !== undefined) {
      if (dataG.length > 0) {
     
        // console.log('dataG -----', dataG)
        fetchData()
      } else {
        console.log('Empty data form ', dataG.length)
      }
    } else {
      console.log('unable to fetch Data')
    }
    // console.log('data graph', dataG)
  }, [dataG,fetchData])
  const GetEvents=(e)=>{
    console.log(e.target.name);
  }
  // console.log(EndArr);
  return (
    <>
      {dataG !== undefined || dataG.length!==0 ? (
        <>
          {dataG.length <= 0 ? (
            <div className=' text-white d-flex align-items-center justify-content-center p-4'>
              <Oval
                heigth='100'
                className='text-white '
                background='white'
                width='100'
                color='white'
                ariaLabel='loading'
              />
              <p>Loading Ido's</p>
            </div>
          ) : (
            <section className='tab-cards'>
              <Tab.Container id='left-tabs-example' defaultActiveKey='first'>
                <div className='container-fluid custom-block'>
                  <Nav variant='pills'>
                    <Nav.Item>
                      <Nav.Link name="first" eventKey='first' onClick={GetEvents}>Upcoming ({upComArr.length})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link name="second" eventKey='second' onClick={GetEvents}>Live({LiveArr.length})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link name="third" eventKey='third' onClick={GetEvents}>Ended ({EndArr.length})</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey='first'>
                      <div className='row'>
                        {upComArr.map((item, index) => {
                          return (
                            <div
                              className='col-lg-4 col-md-6 mb-md-5 mb-3'
                              key={index}
                            >
                              <Link
                                to={`/${'product'}:${item.poolId}`}
                                className='card mb-3'
                                state={{ item: item }}
                              >
                                <div className='d-flex align-items-lg-center'>
                                  <figure className='mb-0 game-img'>
                                    <img
                                      className='img-fluid profile'
                                      src={item.logo}
                                      alt='Gammes'
                                      onError={addDefaultImg}
                                    />
                                  </figure>
                                  <div className='w-100'>
                                    <strong className='card-title'>
                                      ${item.name}
                                    </strong>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      <p className='text-uppercase'>
                                        ${item.name}
                                      </p>
                                      {/* <a href="#" href="#" className='tag-btn text-uppercase'>upcoming</a> */}
                                    </div>
                                  </div>
                                </div>
                                <div className='social-icon-bar'>
                                  <ul>
                                    {socialLinks.map((item, index) => {
                                      return (
                                        <li key={index}>
                                          <figure className='mb-0'>
                                            <img
                                              className='img-fluid'
                                              src={item}
                                              alt='Gammes'
                                            />
                                          </figure>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                                <p className='cut-text'>{item.description}</p>
                                <div className='progress-bar-div'>
                                  <div className='d-flex justify-content-between'>
                                    <p className='mb-2'>
                                      {item.remainTime.day} Days and{' '}
                                      {item.remainTime.hours} Hours
                                    </p>
                                    <span className='bar-result'>0%</span>
                                  </div>
                                  <div className='progress mb-2'>
                                    <div
                                      className='progress-bar'
                                      role='progressbar'
                                      aria-valuenow='25'
                                      aria-valuemin='0'
                                      aria-valuemax='100'
                                    ></div>
                                  </div>
                                  <div className='d-flex justify-content-between'>
                                    <p className='game-price'>0 BUSD</p>
                                    <p>
                                      {item.initialSupply} {item.name}
                                    </p>
                                  </div>
                                </div>
                                <div className='card-footer'>
                                  <div className='card-footer-content'>
                                    <ul>
                                      <li>
                                        <p className='text-capitalize'>
                                          starts
                                        </p>
                                        <div>
                                          <strong className='d-block feature-price blue'>
                                            {time.month}
                                            {time.date}
                                          </strong>
                                          <span className='text-capitalize'>
                                            {time.hour}:{time.min} UTC
                                          </span>
                                        </div>
                                      </li>
                                      <li>
                                        <p className='text-capitalize'>price</p>
                                        <div>
                                          <strong className='d-block feature-price purple'>
                                            {item.price}
                                          </strong>
                                          <span className='text-capitalize'>
                                            ={item.price} $
                                          </span>
                                        </div>
                                      </li>
                                      <li>
                                        <p className='text-capitalize'>
                                          total raise
                                        </p>
                                        <div>
                                          <strong className='d-block feature-price pink'>
                                            ${item.hardCap}
                                          </strong>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                        })}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='second'>
                      <div className='row'>
                        {LiveArr.map((item, index) => {
                          return (
                            <div
                              className='col-lg-4 col-md-6 mb-md-5 mb-3'
                              key={index}
                            >
                              <Link
                                to={`/${'product'}:${item.poolId}`}
                                className='card mb-3'
                                state={{ item: item }}
                              >
                                <div className='d-flex align-items-lg-center'>
                                  <figure className='mb-0 game-img'>
                                    <img
                                      className='img-fluid profile'
                                      src={item.logo}
                                      alt='Gammes'
                                      onError={addDefaultImg}
                                    />
                                  </figure>
                                  <div className='w-100'>
                                    <strong className='card-title'>
                                      ${item.name}
                                    </strong>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      <p className='text-uppercase'>
                                        ${item.name}
                                      </p>
                                      {/* <a href="#" href="#" className='tag-btn text-uppercase'>upcoming</a> */}
                                    </div>
                                  </div>
                                </div>
                                <div className='social-icon-bar'>
                                  <ul>
                                    {socialLinks.map((item, index) => {
                                      return (
                                        <li key={index}>
                                          <figure className='mb-0'>
                                            <img
                                              className='img-fluid'
                                              src={item}
                                              alt='Gammes'
                                            />
                                          </figure>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                                <p className='cut-text'>{item.description}</p>
                                <div className='progress-bar-div'>
                                  <div className='d-flex justify-content-between'>
                                    <p className='mb-2'>
                                      {item.remainTime.day} Days and{' '}
                                      {item.remainTime.hours} Hours
                                    </p>
                                    <span className='bar-result'>0%</span>
                                  </div>
                                  <div className='progress mb-2'>
                                    <div
                                      className='progress-bar'
                                      role='progressbar'
                                      aria-valuenow='25'
                                      aria-valuemin='0'
                                      aria-valuemax='100'
                                    ></div>
                                  </div>
                                  <div className='d-flex justify-content-between'>
                                    <p className='game-price'>0 BUSD</p>
                                    <p>
                                      {item.initialSupply} {item.name}
                                    </p>
                                  </div>
                                </div>
                                <div className='card-footer'>
                                  <div className='card-footer-content'>
                                    <ul>
                                      <li>
                                        <p className='text-capitalize'>
                                          starts
                                        </p>
                                        <div>
                                          <strong className='d-block feature-price blue'>
                                            {time.month}
                                            {time.date}
                                          </strong>
                                          <span className='text-capitalize'>
                                            {time.hour}:{time.min} UTC
                                          </span>
                                        </div>
                                      </li>
                                      <li>
                                        <p className='text-capitalize'>price</p>
                                        <div>
                                          <strong className='d-block feature-price purple'>
                                            {item.price}
                                          </strong>
                                          <span className='text-capitalize'>
                                            ={item.price} $
                                          </span>
                                        </div>
                                      </li>
                                      <li>
                                        <p className='text-capitalize'>
                                          total raise
                                        </p>
                                        <div>
                                          <strong className='d-block feature-price pink'>
                                            ${item.hardCap}
                                          </strong>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                        })}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='third'>
                      <div className='row'>
                        {EndArr.map((item, index) => {
                          return (
                            <div
                              className='col-lg-4 col-md-6 mb-md-5 mb-3'
                              key={index}
                            >
                              <Link
                                to={`/${'product'}:${item.poolId}`}
                                className='card mb-3'
                                state={{ item: item }}
                              >
                                <div className='d-flex align-items-lg-center'>
                                  <figure className='mb-0 game-img'>
                                    <img
                                      className='img-fluid profile'
                                      src={item.logo}
                                      alt='Gammes'
                                      onError={addDefaultImg}
                                    />
                                  </figure>
                                  <div className='w-100'>
                                    <strong className='card-title'>
                                      ${item.name}
                                    </strong>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      <p className='text-uppercase'>
                                        ${item.name}
                                      </p>
                                      {/* <a href="#" href="#" className='tag-btn text-uppercase'>upcoming</a> */}
                                    </div>
                                  </div>
                                </div>
                                <div className='social-icon-bar'>
                                  <ul>
                                    {socialLinks.map((item, index) => {
                                      return (
                                        <li key={index}>
                                          <figure className='mb-0'>
                                            <img
                                              className='img-fluid'
                                              src={item}
                                              alt='Gammes'
                                            />
                                          </figure>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                                <p className='cut-text'>{item.description}</p>
                                <div className='progress-bar-div'>
                                  <div className='d-flex justify-content-between'>
                                    <p className='mb-2'>
                                      {item.remainTime.day} Days and{' '}
                                      {item.remainTime.hours} Hours
                                    </p>
                                    <span className='bar-result'>0%</span>
                                  </div>
                                  <div className='progress mb-2'>
                                    <div
                                      className='progress-bar'
                                      role='progressbar'
                                      aria-valuenow='25'
                                      aria-valuemin='0'
                                      aria-valuemax='100'
                                    ></div>
                                  </div>
                                  <div className='d-flex justify-content-between'>
                                    <p className='game-price'>0 BUSD</p>
                                    <p>
                                      {item.initialSupply} {item.name}
                                    </p>
                                  </div>
                                </div>
                                <div className='card-footer'>
                                  <div className='card-footer-content'>
                                    <ul>
                                      <li>
                                        <p className='text-capitalize'>
                                          starts
                                        </p>
                                        <div>
                                          <strong className='d-block feature-price blue'>
                                            {time.month}
                                            {time.date}
                                          </strong>
                                          <span className='text-capitalize'>
                                            {time.hour}:{time.min} UTC
                                          </span>
                                        </div>
                                      </li>
                                      <li>
                                        <p className='text-capitalize'>price</p>
                                        <div>
                                          <strong className='d-block feature-price purple'>
                                            {item.price}
                                          </strong>
                                          <span className='text-capitalize'>
                                            ={item.price} $
                                          </span>
                                        </div>
                                      </li>
                                      <li>
                                        <p className='text-capitalize'>
                                          total raise
                                        </p>
                                        <div>
                                          <strong className='d-block feature-price pink'>
                                            ${item.hardCap}
                                          </strong>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                        })}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </section>
          )}
        </>
      ) : (
        <h3 className='text-center text-danger'>
          Unable to Fetch Data from Subgraph
        </h3>
      )}
    </>
  )
}

export default MultiChainCards
