import React from "react";

function BannerAdmin() {
    return (
        <div>
            <div className="content">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel dark-theme">
                                <div className="panel-body dashboard-cards">
                                    <div className="row mb-3 justify-content-between align-content-center">
                                        <div className="col-md-4 col-xs-12">
                                            <div className="widget-metric_6 animate bg-primary box-primary-shadow">
                                                <div className="right">
                                                    <span className="value">Number Of Pools</span>
                                                    <span className="title">Users</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xs-12">
                                            <div className="widget-metric_6 animate blue-yellow box-primary-shadow">
                                                <div className="right">
                                                    <span className="value">Total Stakings</span>
                                                    <span className="title">Users</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xs-12">
                                            <div className="widget-metric_6 animate blue-light box-primary-shadow">
                                                <div className="right">
                                                    <span className="value">Admin Users</span>
                                                    <span className="title">2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BannerAdmin;
