import React,{useState,useEffect} from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
// import axios from 'axios'
function EditFaqModal ({ show, handleEditModal,data }) {
  console.log("Edit faqs")  
  const [faq, setFaq] =useState({
    title: '',
    description: ''
  })
  const handleClose = () => {
    setFaq({...faq,title:"",description:""})
    handleEditModal();
  }

  useEffect(()=>{
    
        setFaq({...faq,title:data.title,description:data.description})

    
  },[data,faq])
  const handleChange = e => {
   console.log(e)
    const { name, value } = e.target
    setFaq({ ...faq, [name]: value })


  }
  const handleFaqSubmit = () => {
    // axios
    //   .post('https://holdex-ido-server.server18.arhamsoft.info/api/faqs', [faq.title,faq.description])
    //   .then(res => {
    //     console.log('successfully added faq')
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
    console.log("submit here");
  }

  return (
    <>
    

        <Modal show={show} onHide={handleClose}  className="cms-pages-style">
        <Modal.Header closeButton className="faq-modal">
          <Modal.Title>Manage Faq's</Modal.Title>
        </Modal.Header>
        <Modal.Body className="faq-modal">
          <Form>
            <Form.Group className='mb-3' controlId='FaqTitle'>
              <Form.Label>Faq Title</Form.Label>
              <Form.Control
                type='text'
                name='title'
                value={faq.title}
                onChange={handleChange}
                
                placeholder='Enter Faq Title'
                required
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as ="textarea"
                rows={3}
                name='description'
                value={faq.description}
                placeholder='Enter Description'
                onChange={handleChange}
                required
              />
            </Form.Group>
          
          </Form>
        </Modal.Body>
        <Modal.Footer className="faq-modal">
        <Button className=' light-blue-btn text-capitalize' type='submit' onClick={handleFaqSubmit}>
              Submit
        </Button>
      
        
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditFaqModal
