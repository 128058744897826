import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { faToolbox } from '@fortawesome/free-solid-svg-icons'


function Sidenav () {
  return (
    <>
      <div id='sidebar' className='sidebar'>
        <div className='menu-icon cur-poi'>
          <svg
            aria-hidden='true'
            focusable='false'
            data-prefix='fas'
            data-icon='bars'
            className='svg-inline--fa fa-bars fa-w-14 '
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 448 512'
          >
            <path
              fill='currentColor'
              d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'
            ></path>
          </svg>
        </div>
        <strong className='logo w-100 d-flex justify-content-center'>
        </strong>
        <ul className='main-nav list-unstyled'>
          <li className='active'>
            <span>
              <FontAwesomeIcon className='me-2' icon={faChartPie} />
            </span>
            <Link to='dashboard1'>Dashboard</Link>
          </li>
          <li>
            <span>
              <FontAwesomeIcon className='me-2' icon={faToolbox} />
            </span>
            <Link to='managepools' className='text-white'>
              Manage pools
            </Link>
          </li>
          
          <li>
            <span>
              <FontAwesomeIcon className='me-2' icon={faWrench} />
            </span>
            <Link to='faqs' className='text-white'>
              Manage Faqs
            </Link>
          </li>
          <li>
            <span>
              <FontAwesomeIcon className='me-2' icon={faPrint} />
            </span>
            <Link to='cmspages' className='text-white'>
              CMS PAGES
            </Link>
          </li> 

          <li>
            <span>
              <FontAwesomeIcon className='me-2' icon={faWrench} />
            </span>
            <Link to='settings' className='text-white'>
              Settings
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Sidenav
