import React from "react";
import Card from "react-bootstrap/Card";
import {Row, Col, Form} from "react-bootstrap";

function CmsPage() {
    return (
        <div>
            <div className="content">
                <div className="cms-pg">
                    <Card>
                        <Card.Header className="titles-sett">
                            Manage CMS
                            <button className="d-inline light-blue-btn text-capitalize">Add</button>
                        </Card.Header>
                        <Card.Body>
                            <div className="cms-pages-style">
                                <div className="container">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="cms-pages-style">
                                                <Form>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter Name" />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Label>Message</Form.Label>
                                                        <Form.Control as="textarea" rows={3} />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default CmsPage;
