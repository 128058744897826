import React, { useEffect, useState } from 'react'

import axios from 'axios'
import FaqModal from './addfaqmodal'
import EditFaqModal from './editfaq'
import { TailSpin } from 'react-loader-spinner'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function ManageFaq () {
  const [show, setShow] = useState(false)
  const [faqs, setFaqs] = useState([])
  //edit type mean either we want to Edit faqs or just add new Faq
  const [editModal, setEditModal] = useState('')
  const handleModal = () => {
    setShow(false)
    setEditModal('')
  }
  const handleEditModal = () => {
    setEditModal('')
    setShow(false)
  }

  useEffect(() => {
    axios
      .get('https://holdex-ido-server.server18.arhamsoft.info/api/faqs')
      .then(res => {
        console.log(res.data.data)
        setFaqs(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  const DeleteFaqHandler = (e, item) => {
    console.log(item)
    axios
      .delete(
        `https://holdex-ido-server.server18.arhamsoft.info/api/faqs/${item.id}`
      )
      .then(res => {
        console.log(res)
        e.preventDefault()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const AddFaqHandler = () => {
    setShow(true)
    setEditModal('')
  }
  const EditFaqHandler = item => {
    setEditModal(item)
    setShow(false)
  }
  console.log('value edit', editModal)
  return (
    <div>
      <div className='content '>
        <h3 className='text-white m-4 text-center'>Manage FAQS</h3>
        <button className='d-inline light-blue-btn text-capitalize' onClick={AddFaqHandler}>
          {/* <Link to='/createpool' className='text-white'> */}
          <FontAwesomeIcon className='add-icon' icon={faPlus} /> Add Faq
          {/* </Link> */}
        </button>
        {faqs.length > 0 ? (
          faqs.map((item, index) => {
            return (
              <>
                <div class='manage-pools  border border-secondary  m-4 p-4'>
                  <div class='card-header border-bottom border-primary'>
                    <h5 class='card-title text-white '>
                      {'Q'}
                      {' . '}
                      {item.title}
                    </h5>
                  </div>
                  <div class='card-body text-white'>
                    <p class='card-text'>{item.description}</p>
                    <ul class='nav nav-pills card-header-pills  mx-2'>
                      
                      <li
                        class='nav-item text-white border  border-warning  space-between'
                        onClick={() => EditFaqHandler(item)}
                      >
                        <p class='nav-link text-white'>EDIT</p>
                      </li>
                      <li
                        class='nav-item border border-primary '
                        onClick={() => DeleteFaqHandler(item)}
                      >
                        <p class='nav-link text-white '>DELETE </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )
          })
        ) : (
          <>
            <div className='text-center faq-loader'>
              <TailSpin
                height='150'
                width='150'
                color='#46bdf4'
                ariaLabel='loading'
              />
            </div>
          </>
        )}
      </div>

      <div
        class='modal fade'
        id='exampleModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog' role='document'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='exampleModalLabel'>
                Modal title
              </h5>
              <button
                type='button'
                class='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div class='modal-body'>...</div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button type='button' class='btn btn-primary'>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {show ? <FaqModal show={show} handleModal={handleModal} /> : <></>}
      {editModal !== '' ? (
        <EditFaqModal
          show={true}
          handleEditModal={handleEditModal}
          data={editModal}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default ManageFaq
