import React, { useEffect, useCallback, useState } from 'react'
import { ethers } from 'ethers'
import TokenAbi from '../../utils/token20Abi'
import { useCookies } from 'react-cookie'

const UserInfo = props => {
  console.log('we are in user Info', props)
  console.log('props', props)
  const [cookies] = useCookies(['address'])
  const [approveErr, setApproveErr] = useState('')
  const idoAddress = process.env.REACT_APP_IDO_Address
  const fetchBal = useCallback(async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()

    var address = props.item
    var contract = new ethers.Contract(address, TokenAbi, signer)

    const count = await contract.balanceOf(cookies.address)
    console.log('balc', count.toString())
    props.setter(count.toString())
  }, [cookies.address, props])
  const ApproveUser = useCallback(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    // -------------------------
    // Checking for allowance --
    // -------------------------
    var address = props.item
    var contract = new ethers.Contract(address, TokenAbi, signer)

    contract
      .approve(idoAddress, 1000000000000000)
      .then(res => {
        setApproveErr('')
      })
      .catch(err => {
        console.log(err)
      })
  },[idoAddress,props.item])
  const CheckApprove = useCallback(async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    // -------------------------
    // Checking for allowance --
    // -------------------------
    var address = props.item
    var contract = new ethers.Contract(address, TokenAbi, signer)
    var balanPool = await contract.balanceOf(cookies.address)
    console.log('balnce', balanPool)
    if (parseInt(balanPool) > 0) {
      console.log('balc', balanPool.toString())

      props.setter(balanPool.toString())

      const allowance = await contract.allowance(cookies.address, idoAddress)
      console.log('allowance ', allowance.toString())

      if (parseInt(allowance) <= 0) {
        // if not allowance then go for approve

        setApproveErr('You account is not approved for Participation')
      } else {
        setApproveErr('')
      }
    } else {
      console.log(`You dont have  Enough balance`)
    }
  },[cookies.address,idoAddress,props])

  useEffect(() => {
    // fetchBal()
    CheckApprove()
  }, [cookies.address, fetchBal,CheckApprove, ApproveUser])


  return (
    <div className='row d-flex flex-row'>
      {approveErr !== '' ? (
        <div className='field-wrapper'>
          <p className='text-danger'>{approveErr}</p>
          <button className='light-blue-btn float-center' onClick={ApproveUser}>
            Approve
          </button>
        </div>
      ) : (
        <></>
      )}
      <span className='col-6 text-secondary m-2'>balance:{props.bal}</span>
      <span className='col-6 text-secondary m-2'>Min Buy:{props.minBuy}</span>

      <span className='col-6 m-2'>
        <a href='http://google.com/'>How to participate</a>
      </span>
    </div>
  )
}
export default UserInfo
